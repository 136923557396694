import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import getString from '../utils/get-string';

class EmailConfirmation extends React.Component {
  state = { emailIsVerified: false, error: false }

  componentDidMount() {
    this._isMounted = true

    const userId = window.location.hash.substring(1)

    fetch(`https://site-api.datocms.com/items/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Version': 2,
        Accept: 'application/json',
        Authorization: `Bearer 186716fd2040d981a07945e7a0f0de`,
      },
      body: JSON.stringify({
        data: {
          type: 'item',
          id: '47741',
          attributes: {
            emailisverified: true,
          },
        },
      }),
    })
      .then(response => {
        this.setState({ responseOk: response.ok })
        return response
      })
      .then(res => res.json())
      .then(data => {
        if (this._isMounted) {
          return this.state.responseOk
            ? this.setState({ emailIsVerified: true })
            : this.setState({ error: true })
        }
      })

  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <Layout>
        <Seo title={getString(this.props.strings, "11255402")} />
        <Page
          type={
            this.state.emailIsVerified
              ? 'success'
              : this.state.error
              ? 'error'
              : 'white'
          }
        >
          <Header closeUrl="/lessons">{getString(this.props.strings, "11255405")}</Header>
          <Content center={true}>
            {this.state.emailIsVerified && (
              <h1 className="h2 growing text-transform-uppercase">
                {getString(this.props.strings, "11255613")}
              </h1>
            )}
            {this.state.error && <h1 className="h2 growing">{getString(this.props.strings, "11255620")}</h1>}
            {!this.state.emailIsVerified && !this.state.error && (
              <>
                <h1 className="h2 growing text-transform-uppercase">
                  {getString(this.props.strings, "11255402")}
                </h1>
                <div className="page-text-container">
                  <p className="h6">{getString(this.props.strings, "11255633")}</p>
                </div>
              </>
            )}
          </Content>
          <Footer>
            {this.state.emailIsVerified && (
              <FooterLinkNext to="/login">{getString(this.props.strings, "11255182")}</FooterLinkNext>
            )}
            {this.state.error && (
              <FooterLinkNext to="/">{getString(this.props.strings, "11221034")}</FooterLinkNext>
            )}
          </Footer>
        </Page>
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11255402", "11255405", "11255613", "11255620", "11255633", "11255182", "11221034"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <EmailConfirmation {...data} {...props} />}
  />
)
